export const getSeo = (seo: any, seoContent?: any) => {
  let seoData: any = { seo_description: '', seo_title: '', seo_image: '', seo_canonical: null }

  if (Array.isArray(seo)) {
    seoData = seo.length > 0 ? seo[0] : {}
  } else {
    seoData = seo
  }

  const { seo_description, seo_title, seo_image, seo_canonical } = seoData ? seoData : {}
  const { description, title, image } = seoContent ? seoContent : {}

  let canonical = null

  if(seo_canonical && seo_canonical.cached_url){
    canonical = seo_canonical.cached_url
  }

  return {
    seo_description: seo_description || description || '',
    seo_title: seo_title || title || '',
    seo_image:
      seo_image?.filename?.length > 0 ? seo_image?.filename : '' || image || '',
    seo_canonical: canonical
  }
}
