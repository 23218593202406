import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'
import SbEditable from 'storyblok-react'

import { storageKeys } from 'src/constants'
import MailchimpForm from 'src/components/MailchimpForm/MailchimpForm'
import Image from 'src/components/UI/Image/Image'
import { CloseIcon } from 'src/components/UI/Icon/Icon'

import { Props, ContentProps } from './types'
import * as styles from './NewsletterPopup.module.scss'

const listDefault =
  'https://wardwines.us1.list-manage.com/subscribe/post?u=c3ae07bf83d2709cd54acc0a2&amp;id=dbbe41f3c8'
const listPremium =
  'https://wardwines.us1.list-manage.com/subscribe/post?u=c3ae07bf83d2709cd54acc0a2&amp;id=489c9d8f07'

const Content = ({ blok, cookie, onClick }: ContentProps): JSX.Element => {
  const {
    label,
    title,
    subtitle,
    policy,
    button,
    list,
    image,
    background_color,
  } = blok

  const texts = { label, title, subtitle, policy, button }

  const mailchimpList = list === 'premium' ? listPremium : listDefault
  const gotImage = image && image.filename ? true : false
  return (
    <SbEditable content={blok}>
      <Box
        width={1}
        id="newsletter-popup"
        className={
          cookie.hide
            ? [styles.newsletterPopup, styles.hidden].join(' ')
            : styles.newsletterPopup
        }
        onClick={(e: any) => {
          if (e.target.id === 'newsletter-popup') {
            onClick()
          }
        }}
      >
        <Flex
          width={1}
          className={[
            styles.innerWrapper,
            gotImage ? styles.gotImage : '',
          ].join(' ')}
          backgroundColor={background_color}
          flexDirection={['column', null, null, 'row']}
        >
          <Box
            as="button"
            onClick={onClick}
            className={styles.close}
            aria-label="Close menu"
          >
            <CloseIcon />
          </Box>

          {image && image.filename && (
            <Box className={styles.imageWrapper}>
              <Image
                src={image.filename}
                alt={title}
                sizes={{
                  mobile: '50vw',
                  tablet: '33vw',
                  desktop: '33vw',
                }}
              />
            </Box>
          )}
          <Flex
            width={1}
            flexDirection={['column', null, 'column']}
            justifyContent={['center']}
            alignItems="center"
            className={styles.content}
          >
            <span className={styles.label}>{label}</span>
            <h2 className={styles.title}>{title}</h2>
            <MailchimpForm
              className={styles.form}
              url={mailchimpList}
              use={{ firstName: false, lastName: false }}
              texts={texts}
              isPremium={list === 'premium'}
            />
          </Flex>
        </Flex>
      </Box>
    </SbEditable>
  )
}

const NewsletterPopup = ({ blok }: Props): JSX.Element => {
  const [cookie, setCookie] = useState({ hide: false, show: false })

  useEffect(() => {
    const handleScroll = () => {
      const cookieAccepted = localStorage.getItem(
        storageKeys.NEWSLETTER_ACCEPTED
      )
      if (!cookieAccepted) {
        setTimeout(() => {
          setCookie({
            ...cookie,
            show: true,
          })
          window.removeEventListener('scroll', handleScroll)
        }, 3000)
      } else {
        window.removeEventListener('scroll', handleScroll)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClick = () => {
    setCookie({
      ...cookie,
      hide: true,
    })
    setTimeout(() => {
      localStorage.setItem(
        storageKeys.NEWSLETTER_ACCEPTED,
        JSON.stringify(true)
      )
      setCookie({
        ...cookie,
        show: false,
      })
    }, 1000)
  }

  return (
    <React.Fragment>
      {cookie.show ? (
        <Content blok={blok} cookie={cookie} onClick={() => handleClick()} />
      ) : null}
    </React.Fragment>
  )
}

export default NewsletterPopup
