import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useRecoilState } from 'recoil'
import isNode from 'is-node'
import { globalHistory } from '@reach/router'

import {
  topListState,
  ourFavoritesState,
  activePopupState,
  userActivityState,
} from 'src/recoil/atoms'
import Header from 'src/storyblok/GlobalModules/Header/Header'
import ProductPopup from 'src/storyblok/GlobalModules/ProductPopup/ProductPopup'
import GuidePopup from 'src/storyblok/GlobalModules/GuidePopup/GuidePopup'
import Footer from 'src/storyblok/GlobalModules/Footer/Footer'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import GoogleAnalytics from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'
import globalData from 'src/json/globalData.json'
import NewsletterPopup from 'src/storyblok/GlobalModules/NewsletterPopup/NewsletterPopup'

import { Props } from './types'

export default ({ children, pageContext }: Props): JSX.Element => {
  const { story }: any = pageContext ? pageContext : {}
  const {
    header,
    footer,
    seo,
    toplist,
    favorites,
    product_popup,
    guide_popup,
    newsletter_popup,
  }: any = globalData ? globalData : {}

  const [, setTopList] = useRecoilState(topListState)
  const [, setOurFavorites] = useRecoilState(ourFavoritesState)
  const [activePopup] = useRecoilState(activePopupState)
  const [, setUserActivity] = useRecoilState(userActivityState)
  const [loadScript, setLoadScript] = useState(false)
  let hasScrolled = false
  let hasStayed = false
  const seo_data: any = seo ? seo.content : {}
  const { seo_description, seo_title, seo_image, seo_canonical } =
    getSeo(seo_data)

  // Setup scrolling variables
  let isScrolling: any
  let timeScrolled = 0
  let timeout: any = null
  let top = 0
  const documentHeight =
    !isNode &&
    document &&
    document.documentElement &&
    document.documentElement.scrollHeight
      ? document.documentElement.scrollHeight
      : 0

  useEffect(() => {
    setTimeout(() => {
      setLoadScript(true)
    }, 1000)
  }, [])

  useEffect(() => {
    const removeListener = globalHistory.listen(() => {
      hasScrolled = false
      hasStayed = false
      clearTimeout(timeout)
      stayHandler()
    })
    return () => {
      removeListener()
    }
  }, [globalHistory.listen])

  const stayHandler = () => {
    timeout = setTimeout(() => {
      //  set user activity to 'has stayed
      hasStayed = true
      setUserActivity({ hasScrolled: hasScrolled, hasStayed: true })
    }, 120000)
  }

  // Listen for scroll to fix play-button-scroll bugg
  const onScroll = () => {
    // Clear our timeout throughout the scroll
    if (timeScrolled === 0) {
      top = window.scrollY
    }
    timeScrolled++
    const scrollPercentage = (window.innerHeight - top) / documentHeight
    if (scrollPercentage > 0.25 && !hasScrolled) {
      console.log('set user activity hasScrolled to true')
      hasScrolled = true
      setUserActivity({ hasStayed: hasStayed, hasScrolled: true })
    }
    window.clearTimeout(isScrolling)
    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function () {
      // Run the callback
      timeScrolled = 0
    }, 0)
  }
  useEffect(() => {
    // Set toplist global state
    setTopList(
      toplist?.content?.products?.length > 0 ? toplist.content.products : []
    )
    // Set favorites global state
    setOurFavorites(
      favorites?.content?.products?.length > 0 ? favorites.content.products : []
    )
    stayHandler()
    window.addEventListener('scroll', onScroll, false)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <React.Fragment>
      {product_popup?.content && activePopup && (
        <ProductPopup blok={product_popup.content} />
      )}
      {guide_popup?.content && <GuidePopup blok={guide_popup.content} />}
      {newsletter_popup?.content && (
        <NewsletterPopup blok={newsletter_popup.content} />
      )}
      {header?.content && (
        <Header
          blok={header.content}
          fullSlug={story?.full_slug ? story.full_slug : ''}
        />
      )}

      <React.Fragment>
        <GoogleAnalytics />
        {seo_title && (
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
          />
        )}
        <SEO
          title={story?.name && story?.slug !== 'home' ? story.name : ''}
          metaDescription={seo_description}
          image={seo_image}
          canonical={seo_canonical}
        />
      </React.Fragment>

      {children}
      {footer?.content && <Footer blok={footer.content} />}
    </React.Fragment>
  )
}
